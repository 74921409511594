<template>
  <div>
    <h1>Mes Hobbies</h1>     
    <div class="mb-3 mt-3">
      <!-- {{ hobbies }}
      <hr>
      {{ (profile.hobbies||[]) }}
      <hr> -->
      <span v-for="hobby, hobbyIndex in hobbies" :key="hobbyIndex" >
        <a href="javascript:void(0)" @click="toggleHobby(hobby)" class="btn me-3 mb-3" :class="{'bg-primary text-white': (profile.hobbies||[]).indexOf(hobby.label)>=0,'bg-light': (profile.hobbies||[]).indexOf(hobby.label)<0}">
          <i  :class="('bi bi-'+ hobby.img)"></i> {{ hobby.label }}
        </a>
      </span> 
    </div>
    
    <a href="javascript:void(0)" class="btn btn-primary d-block mb-3" @click="enregistrerUserClick">Enregistrer</a>
</div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { useProfileStore } from "./stores/profiles";
import {  onMounted, computed, ref } from "vue";
import { useTokenStore } from "./stores/tokens";
const router = useRouter();
const tokenStore= useTokenStore();
const profileStore = useProfileStore();
const profile= computed(() => {
  return profileStore.profile;
});

const hobbies= ref([
  {img:'camera', label:'Photographie'},
  {img:'bag-heart', label:'Shopping'},
  {img:'mic', label:'Karaoke'},
  {img:'universal-access-circle', label:'Yoga'},
  {img:'cookie', label:'Cuisiner'},
  {img:'dribble', label:'Tennis'},
  {img:'person-walking', label:'Courir'},
  {img:'tsunami', label:'Nager'},
  {img:'boxes', label:'Art'},
  {img:'globe-americas', label:'Voyager'},
  {img:'activity', label:'Extreme'},
  {img:'file-music', label:'Musique'},
  {img:'cup-straw', label:'Bar'},
  {img:'joystick', label:'Jeux vidéo'}
]);
const enregistrerUserClick=async ()=>{
  await profileStore.saveProfile(profile.value)
  router.push('/search-preferences');

}
const toggleHobby=(hobby)=>{
  if( profile.value.hobbies==null){
    profile.value.hobbies=[];
  }
  profile.value.hobbies= profile.value.hobbies.reduce((c,i)=>{if( i!= hobby.label){c.push(i);} return c;},profile.value.hobbies.indexOf(hobby.label)<0?[hobby.label]:[])
}
onMounted(() => {
  if( !tokenStore.isLoggedIn){
    
    router.push('/')
  }else{
    profileStore.fetchProfile();
  }
});



</script>