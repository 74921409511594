<style scoped>
#app h5{display: inline;font-size:16px}
#app span.float-end{
  /* transform:translate(0px, -15px); */
  color:#F27721;font-size:18px
}


</style>
<template>
  <div>
    <div style="text-align:center" class="position-relative">
      <img src="twist_logo.png" style="height:40px" />
    </div>
    <div style="clear: both;" class="mb-5"></div>
  
    <div v-if="editField=='firstName'">
      <h1 class="mb-4">Je m'appelle</h1>
      <div class="form-floating mb-4" >
        <input type="text" class="form-control" id="floatingInput" placeholder="Prénom" v-model="profile.firstName" >
        <label for="floatingInput">Prénom</label>
      </div>      
    </div>

    <div  v-if="editField=='gender'">
      <h1 class="mb-4">Je suis</h1>
      <div class="d-flex flex-column flex-md-row gap-4 py-md-5 align-items-center justify-content-center">
        <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
          <div class="position-relative" v-for="text, index in ['M', 'F', 'X']" :key="text">
            <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.gender=text" :checked="profile.gender==text">
            <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
              <span class="d-block small opacity-75">{{ genders[text] }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4" v-if="editField=='searchingForGender'">
      <h1 >Interessé par </h1>  
      <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
        <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
          <div class="position-relative" v-for="text, index in ['M', 'F', 'X', 'A']" :key="text">
            <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.searchingForGender=text" :checked="profile.searchingForGender==text">
            <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
              <span class="d-block small opacity-75">{{ genders[text] }}</span>
            </label>
          </div>
        </div>
      </div>
      
    </div>

    <div v-if="editField=='city'">
      <h1 class="mb-4">Je veux twister autour de </h1>  
      <div class="mb-4 form-floating position-relative" > 
        <div class="input-group mb-3">
          <input type="text" class="form-control" id="floatingInput" autocomplete="off"  placeholder="Ville" v-model="profile.city" @keyup="handleCityKeyUp" @blur="handleOnBlur">
            
          <button class="btn btn-outline-secondary" type="button" id="button-addon2"
            v-if="locationEnabled" @click="handleCurrentLocation"
          ><i class="bi bi-geo-alt" ></i></button>
        </div>
        <label for="floatingInput">Localisation</label>
        
        <!-- <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button> -->
        <ul class="list-group" v-if="cities_matched && cities_matched.length>0 && displaySearchCities">
          <li  v-for="match, matchIndex in cities_matched" :key="matchIndex" class="list-group-item">
            <a class="dropdown-item" href="javascript:void(0)" @click="selectLocation(match)">{{match.label}} - {{match.zip_code}} - {{match.department_name}}</a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="editField=='age'">
      <h1 class="mb-4">Age </h1>  
      <div class="form-floating mb-4" >
        <input type="number" class="form-control" id="floatingInput" placeholder="Age" v-model="profile.age" >
        <label for="floatingInput">Age (ans)</label>
      </div>
    </div>

    <div v-if="editField=='searchingFor'">
      <h1 >Je recherche</h1>  
    
      <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
        <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
          <div class="position-relative" v-for="text, index in ['Relation longue durée', 'Relation courte', 'De simples rencontres', 'Je ne sais pas']" :key="text">
            <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.searchingFor=text" :checked="profile.searchingFor==text">
            <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
              <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
              <span class="d-block small opacity-75">{{ text }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editField=='mainPhoto'">
      <h1 class="mb-4">Ajoute ta photo principale !</h1>  
      <div class="mb-3 ">
        <div class="position-relative" style="height: 200px;">
          <div class="position-absolute top-50 start-50 translate-middle">
            
            
            <a href="javascript:void(0)" 
              id="a_mainPhoto"
              @click="handleMainPhotoClick"
              data-bs-toggle="modal" data-bs-target="#exampleModal"
              >
              <ProfileMainPhotoComponent :profile="profile" :size="200" class="rounded-5"></ProfileMainPhotoComponent>
            </a>
          </div>

        </div>
        
        
      </div>
    </div>

    <a href="javascript:void(0)" @click="checkNext()" class="btn btn-primary float-end mt-2">Suivant</a>
    <!-- <a href="javascript:void(0)" @click="enregistrerUserClick()">Valider</a> -->
<!-- Modal photo-->
<div class="modal fade modal-fullscreen"  data-bs-backdrop="static"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content rounded-4">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter une photo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="()=>{newFile=''}"></button>
      </div>
      <!-- <div v-show="newFile">new file</div>
      <div v-show="!newFile">not new file</div> -->

      <div class="mx-auto mt-2" v-show="newFile">
        <div style="width:{{maxWidth}}px;height:{{maxWidth}}px;">
          <canvas id="canvas" style="width:100%;height:100%;" >
      
          </canvas>
        </div>
      </div>
        
    
        <a href="javascript:void(0)" class="mx-auto mt-2 mb-2" @click="()=>{inputFile.click();}"  v-show="!newFile">
          <ProfilePhotoComponent :profile="profile" :index="photoType" :size="maxWidth" :ratio="1" class="rounded-3"></ProfilePhotoComponent>
        </a>

        <div  v-if="photoType!='main'" 
          class="mx-auto" style="width:{{maxWidth}}px"
          >
          <a href="javascript:void(0)" style="overflow:hidden;display:block;" class="btn btn-secondary d-block mt-3 mb-3" 
            @click="()=>{selectedPhotoIndex=photoType}"
            data-bs-toggle="modal" data-bs-target="#exampleModalDescPhoto"
            >
              {{ (profile.photos_description||[])[photoType]??'Ajouter une description' }}
              <i class="bi bi-chevron-compact-right"></i>
          </a>
        </div>

      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" @click="uploadNewFile(photoType)" data-bs-dismiss="modal">Enregistrer</button>
      </div> -->
      <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
          @click="()=>{newFile=''}">
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
            @click="uploadNewFile(photoType)"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>
    </div>
  </div>
</div>







    
    <input class="form-control d-none" type="file" @change="selectFile" accept="image/*" ref="inputFile"/>

    <div style="display: none;">
      <img :src="newFile" @load="handleImgLoaded"  />
    </div>
    <!-- <div class="mb-3">
      <a href="javascript:void(0)" @click="uploadNewFile('main')"  class="btn d-block btn-primary ">Charger</a>
    </div> -->


  </div>
</template>
<script setup>

import { useProfileStore } from "./stores/profiles";
import { useCitiesStore } from "./stores/cities";
import { useTokenStore } from "./stores/tokens";
import {  onMounted, computed, ref, watch } from "vue";
import { useRouter } from 'vue-router'
import ProfileMainPhotoComponent from './components/ProfileMainPhotoComponent.vue'
import ProfilePhotoComponent from './components/ProfilePhotoComponent.vue'
const profileStore = useProfileStore();
const router = useRouter();

const citiesStore=useCitiesStore();
const tokenStore= useTokenStore();
//const search_location=ref('');
const displaySearchCities=ref(false);
const profilEdition = ref(false);
const profile = computed(() => {
  //console.log("computed profile from profile")
  return profileStore.profile;
});


watch(profile, (/*newVal*/)=>{
  checkNext()
  // if((newVal.city||'')==''){
  //   handleCurrentLocation();
  // }
})

const editField=ref('')
const discardNextSave=ref(false)
const checkNext=()=>{
  //editField=firstName, gender, searchingForGender, city, age, searchingFor, mainPhoto

  console.log("checkNext")
  var values=['firstName', 'gender', 'searchingForGender', 'city', 'age', 'searchingFor']

  var missing='';
  for(var i=0;i<values.length;i++){
    if(missing=='' && ((profile.value[values[i]] ||'')=='')){
      //profilEdition.value=true;
      missing=values[i];
      
    }
    if(missing=='' && values[i]=='city' && profile.value.geo_location==null){
      missing='city';
    }
  }
  var el=null;
  if( missing!=''){
    editField.value=missing;
    el=document.getElementById("div_"+missing);
    //console.log('missing', missing, el)
  }
  
  if( missing==''){
    if(( profile.value.main_photo||'')==''){
      if( discardNextSave.value==false){
        enregistrerUserClick()
      }
      discardNextSave.value=true;
      
      editField.value='mainPhoto'
      el=document.getElementById("a_mainPhoto");
    }else  {
      router.push('/profile')
    }
  }
  if( el){
      window.setTimeout(()=>{el.click();el.scrollIntoView({behavior: 'smooth'})},500)
      //el.click();
    }

    console.log('editField',editField.value)

}

const selectedPhotoIndex=ref(0)
const genders=ref({'M':'Homme', 'F':'Femme', 'X':'Non-binaire', 'A':'Tout le monde'})

const newFile= ref('');
const inputFile=ref(null);

const handleCityKeyUp=(e)=>{
  citiesStore.searchFor(profile.value.city);
  console.log(e)
  displaySearchCities.value=true;
}

const photoType=ref('main');
//const svgPhoto = ref(null)
const uploadNewFile=async (type)=>{

  if( imgInfo.img==null){
    
    return ;
  }
  //console.log('svgPhoto', svgPhoto.value, type)
  render(true);
  var dataUrl=canvas.toDataURL('image/png',1);
  //console.log(dataUrl)
  
  //console.log('uploadNewFile', newFile.value );
  await profileStore.uploadNewFile({type:type,content:dataUrl});
  profileStore.fetchProfile();
  newFile.value='';

}

const  readFile=(file)=>{
  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    const result = event.target.result;
    // Do something with result
    //console.log("result", result)
    newFile.value=result

  });

  // reader.addEventListener('progress', (event) => {
  //   if (event.loaded && event.total) {
  //     const percent = (event.loaded / event.total) * 100;
  //     //console.log(`Progress: ${Math.round(percent)}`);
  //   }
  // });
  reader.readAsDataURL(file);
}
// console.log(window.width)
var max=400;
if( window.screen.availWidth-32<400){
  max=window.screen.availWidth-32;
}
const maxWidth=ref(max);
const viewportTransform = {
      x: 0,
      y: 0,
      scale: 1
    }
    let previousX = 0, previousY = 0, isDragStart=false;

  
const updatePanning = (e) => {
  const localX = e.clientX;
  const localY = e.clientY;

  //console.log('update panning', e.clientX, e.clientY)

  viewportTransform.x += localX - previousX;
  viewportTransform.y += localY - previousY;

  previousX = localX;
  previousY = localY;
}
const updateZooming = (e) => {
  //const oldScale = viewportTransform.scale;
  const oldX = viewportTransform.x;
  const oldY = viewportTransform.y;

  const localX = max/2;//e.clientX;
  const localY = max/2;//e.clientY;

  const previousScale = viewportTransform.scale;

  const newScale = viewportTransform.scale += e.deltaY * -0.01;
  //console.log('newScale', newScale)

  const newX = localX - (localX - oldX) * (newScale / previousScale);
  const newY = localY - (localY - oldY) * (newScale / previousScale);

  viewportTransform.x = newX;
  viewportTransform.y = newY;
  viewportTransform.scale = newScale;
}

const onMouseWheel = (e) => {
  updateZooming(e)

  render()

  
}
const onMouseMove = (e) => {
  if( isDragStart){
    updatePanning(e);
    render()

  }
}
const onMouseDown=(e)=>{

  isDragStart=true;
  previousX = e.clientX;
  previousY = e.clientY;

  //console.log('onmousedown', previousX, previousY)
}

const onMouseUp=(/*e*/)=>{
  isDragStart=false;
  //console.log('onmouseup',e)
}
//https://harrisonmilbradt.com/articles/canvas-panning-and-zooming
function render(discardCicle){
  if( ctx!=null){

    if( imgInfo.w*viewportTransform.scale<max){
      viewportTransform.scale=max/imgInfo.w
    }
    if( imgInfo.h*viewportTransform.scale<max){
      viewportTransform.scale=max/imgInfo.h
    }
    if( viewportTransform.x>0){
      viewportTransform.x=0;
    }
    if( viewportTransform.y>0){
      viewportTransform.y=0;
    }

    if( viewportTransform.x + imgInfo.w*viewportTransform.scale<max){
      viewportTransform.x=max-imgInfo.w*viewportTransform.scale;
    }

    if( viewportTransform.y + imgInfo.h*viewportTransform.scale<max){
      viewportTransform.y=max-imgInfo.h*viewportTransform.scale;
    }

    //console.log('x', viewportTransform.x, 'y', viewportTransform.y, 'scale', viewportTransform.scale)

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setTransform(viewportTransform.scale, 0, 0, viewportTransform.scale, viewportTransform.x, viewportTransform.y);
    
    if( imgInfo.img!=null){
      ctx.drawImage(imgInfo.img,0,0,imgInfo.w,imgInfo.h);
    }
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    if( discardCicle!=true){
      ctx.strokeStyle = "rgb(255,255,255)";
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.arc(max/2,max/2, max/2, 0, 2 * Math.PI);
      ctx.stroke();
    }
  }
}

function handleTouch(e, singleTouchHandler)
{
    if ( e.touches.length == 1 )
    {
        singleTouchHandler(e.touches[0])
    }
    else if ( e.touches.length == 2)
    {
      if( e.type=='touchmove'){
        isDragStart = false
        handlePinch(e)
        render()
      }
      if( e.type=='touchdown'){
        initialPinchDistance=null   
      }

    }
    e.stopPropagation();
}

 let initialPinchDistance = null
 let initialPinchScale=null;
// let lastZoom = cameraZoom

function handlePinch(e)
{
    e.preventDefault()
    
    let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }
    
    // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
    let currentDistance = Math.sqrt((touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2)
    
    if (initialPinchDistance == null)
    {
        initialPinchDistance = currentDistance
        initialPinchScale=viewportTransform.scale;
    }
    else
    {
      //viewportTransform.scale=(currentDistance/initialPinchDistance)*initialPinchScale ;
      
      const oldX = viewportTransform.x;
      const oldY = viewportTransform.y;

      const localX = max/2;//e.clientX;
      const localY = max/2;//e.clientY;

      const previousScale = viewportTransform.scale;

      const newScale = (currentDistance/initialPinchDistance)*initialPinchScale ;
      //console.log('newScale', newScale)

      const newX = localX - (localX - oldX) * (newScale / previousScale);
      const newY = localY - (localY - oldY) * (newScale / previousScale);

      viewportTransform.x = newX;
      viewportTransform.y = newY;
      viewportTransform.scale = newScale;


    }
}

var canvas = null;//document.getElementById("canvas");
var ctx = null;

var imgInfo={
  img:null,
  w:0,
  h:0
}
const handleImgLoaded=(e)=>{
  console.log('handleImgLoaded', e, e.target, e.target.width)
  
  viewportTransform.x = 0;
  viewportTransform.y =0;
  viewportTransform.scale =1;

  var width= e.target.width;
  var height= e.target.height;
  
  //viewportTransform
  if( width>height){
    // height=max/width*height;
    // width=max;
    width=max/height*width;
    height=max;
    viewportTransform.x=(max-width)/2.0
  }else{
    // width=max/height*width;
    // height=max;
    height=max/width*height;
    width=max;
    viewportTransform.y=(max-height)/2.0
  }
  canvas.width=max;
  canvas.height=max;
  //console.log(e.target.width, e.target.height, 'w/h=>', width, height)

  imgInfo.w=width;
  imgInfo.h=height;
  imgInfo.img=e.target;

  render();
}

const selectFile=(e)=>{
  console.log("selectFile" ,e, e.target.files)
  if( e.target.files!= null && e.target.files.length>0){
    readFile(e.target.files[0]);
  }
}
const cities_matched = computed(() => {
  return citiesStore.matched;
});
const locationEnabled=ref(false);

const handleCurrentLocation=async ()=>{
console.log('ici handleCurrentLocation')
  navigator.geolocation.getCurrentPosition(
        async (pos) =>{
          // alert('pos')
          // alert(pos)
          console.log('pos', pos)
          const crd = pos.coords;
        
          var cities= await citiesStore.nearby({long:crd.longitude, lat:crd.latitude});
          if(cities && cities.length>0){
            selectLocation(cities[0]);
          }
      }, 
      /*error*/ (err) =>{
        // alert('err'+err.message)
        console.log(err)
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }, 
      /*options*/
      {
        enableHighAccuracy: true,
        timeout: 3000,
        maximumAge: 60*1000,
      }
      );
}
const selectLocation=(city)=>{
  profile.value.city=city.label;
  // profile.value.geo_location={
  //   lat:+city.latitude,
  //   long:+city.longitude
  // };

  profile.value.geo_location={ type: "Point", coordinates: [+city.longitude, +city.latitude ] }

  displaySearchCities.value=false;
}



const handleMainPhotoClick=()=>{
  console.log('handleMainPhotoClick')
  photoType.value='main';
  inputFile.value.click();
}

const enregistrerUserClick=async ()=>{
  
  await profileStore.saveProfile(profile.value)
  //checkProfileOnboarding();
  profilEdition.value=false;
  
}
onMounted(() => {
  if( !tokenStore.isLoggedIn){
    
    router.push('/')
  }else{
    
    locationEnabled.value= "geolocation" in navigator;
    profileStore.fetchProfile();

    canvas =document.getElementById("canvas");
    ctx =canvas.getContext("2d");
    canvas.addEventListener('mousedown', onMouseDown);
    canvas.addEventListener('mouseup', onMouseUp);
    canvas.addEventListener('mousemove', onMouseMove);
    canvas.addEventListener("wheel", onMouseWheel);
    // canvas.addEventListener('mousedown', onPointerDown)
     canvas.addEventListener('touchstart', (e) => handleTouch(e, onMouseDown))
    // canvas.addEventListener('mouseup', onPointerUp)
     canvas.addEventListener('touchend',  (e) => handleTouch(e, onMouseUp))
    // canvas.addEventListener('mousemove', onPointerMove)
     canvas.addEventListener('touchmove', (e) => handleTouch(e, onMouseMove))
    // canvas.addEventListener( 'wheel', (e) => adjustZoom(e.deltaY*SCROLL_SENSITIVITY))
    
    window.scrollTo(0,0);
    }
});
</script>