import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { /*createMemoryHistory ,createWebHistory*/createWebHistory, createRouter } from 'vue-router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"
// import HomeView from './HomeView.vue'
// import AboutView from './AboutView.vue'
import LoginView from './LoginView.vue'
import ResetPasswordView from './ResetPasswordView.vue'
import LogoutView from './LogoutView.vue'
import ProfileView from './ProfileView.vue'
import InvitationView from './InvitationView.vue'
import MessageView from './MessageView.vue'
import PendingView from './PendingView.vue'
import SearchPreferenceView from './SearchPreferenceView.vue'
import ProfileHobbiesView from './ProfileHobbiesView.vue'
import SearchView from './SearchView.vue'
import GoogleCallbackView from './GoogleCallbackView.vue'
import FacebookCallbackView from './FacebookCallbackView.vue'
import SnapchatCallbackView from './SnapchatCallbackView.vue'
import OnboardingView from './OnboardingView.vue'

const routes = [
  { path: '/', component: LoginView },/*login & home */
  { path: '/logout', component: LogoutView },
  { path: '/onboarding', component: OnboardingView},
  { path: '/profile', component: ProfileView },
  { path: '/profile-hobbies', component: ProfileHobbiesView },
  { path: '/search', component: SearchView },
  { path: '/pendings', component: PendingView},
  { path: '/messages', component: MessageView},
  { path: '/invitation', component: InvitationView},
  { path: '/twist-with-:from(.*)', component: InvitationView,  props: route => ({ invitationId: route.query.invitation })},
  { path: '/callback', component:GoogleCallbackView },
  { path: '/callback-fb', component:FacebookCallbackView },
  { path: '/callback-snap', component:SnapchatCallbackView },
  { path: '/search-preferences', component:SearchPreferenceView },
  { path: '/reset-password', component:ResetPasswordView ,  props: route => ({ uuid: route.query.uuid, aid: route.query.aid })},
  //{ path: '/about', component: AboutView },
]

const router = createRouter({
  linkActiveClass:'active',
  history: createWebHistory(),// createWebHistory(),//createMemoryHistory(),
  routes,
})
createApp(App)
    .use(createPinia())
    .use(router)
    .mount('#app')
