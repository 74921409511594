<template>
    <div class="position-relative">
        <div :style="styleBackgroundImage" :class="className"></div>
        <div v-if="description && profile.photos_description && profile.photos_description[index]" class="position-absolute top-0 left-0 w-100 text-center pt-2 pb-2" style="background-color:#FFFFFF88">
            {{ profile.photos_description[index] }}
        </div>
    </div>
</template>
<script setup>
    import { usePhotosStore} from "../stores/photos";
    import {defineProps,computed, watch} from "vue";
    const props = defineProps(['profile', 'size', 'class', 'ratio', 'index','height', 'description'])
    const profile= computed(()=>{
        photosStore.fetchPhotoFromProfile(props.profile, props.index);
         return props.profile});
    const size= computed(()=>{ return props.size});
    const index= computed(()=>{ return props.index});
    const ratio= computed(()=>{ return props.ratio ||1});
    const description= computed(()=>{ return props.description||false});
    const height= computed(()=>{ return props.height});
    const className= computed(()=>{ return props.class});
    const photosStore= usePhotosStore();
    const photos= computed(()=>{
        //console.log("computed photos ")
        return photosStore.photos;
    });
    
    const styleBackgroundImage=computed(()=>{
        //console.log(profile.value.photos, index.value)
        if( profile.value.photos && index.value){
        return [
                photos.value[profile.value.photos[index.value]]==null ? 'border:1px dashed #6C757D ':'',
                'background-image:url("'+(photos.value[profile.value.photos[index.value]]??'photo-missing.jpg')+'");',
                'width:'+((size.value!= null)?size.value+'px':'100%'),
                'height:'+((height.value!= null)?height.value+'px':(size.value*ratio.value)+'px'),
                'background-size:cover',
                'background-position: center;'
            ].join(';');
        }else{
            return [
                //'background-image:url("'+(photos.value[profile.value.photos[index.value]]??'photo-missing.jpg')+'");',
                'width:'+size.value+'px',
                'height:'+(size.value*ratio.value)+'px',
                'background-size:cover',
                'background-position: center;'
            ].join(';');
        }
    })

    watch(profile, (newProfile, oldprofile)=>{
        if( newProfile.main_photo!= oldprofile.main_photo){
            //console.log("watch profile ", profile.value)
            photosStore.fetchPhotoFromProfile(profile.value, index.value);
        }
    })
    
    // const getImgStyle=()=>{
    //     return 'width:'+size.value+'px;height:'+size.value+'px';
    // }
</script>