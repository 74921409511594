<template>
   <div>
        <div style="text-align:center" class="position-relative">
            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="btn btn-outline-primary position-absolute bottom-0 end-0">?</a>
            <img src="twist_logo.png" style="height:40px" />
            <h2 class="mt-2">Tu <span class="twist">valides</span> ?</h2>
        </div>



        <!-- Modal -->
        <div class="modal fade"  id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Comment ça marche ?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    <div style="margin-top:-12px;float:left;width:50px; height:50px;border-radius:100px; border:3px dotted orange;margin-right:15px"></div>
                    <span>Au moins une personne doit encore valider le Twist.</span>
                </div>
                <div style="clear: both;"></div>
                <div class="mt-3">
                    <div style="margin-top:-12px;float:left;width:50px; height:50px;border-radius:100px; border:3px solid green;margin-right:15px"></div>
                    <span> Le Twist a déjà été validé par la personne.</span>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button> -->
            </div>
            </div>
        </div>
        </div>

        <div v-if="pending && pending.length==0 && thisIsATwist==null">
            <div class=" text-center" style="margin-top:100px">
                Pas de <b class="twist">TWISTS</b> en attente pour le moment...

            </div>
            <div class=" text-center" style="margin-top:100px">
                Forme <a href="javascript:void(0)" @click="()=>{router.push('/invitation')}">plus de paires</a> avec tes amis pour augmenter tes chances de rencontres !

            </div>
        </div>
        <div v-if="thisIsATwist" class="position-relative" >

            <ProfileMainPhotoComponent :profile="thisIsATwist.twists.filter(t=>{return t.profileIds.indexOf(profile._id)>=0})[0].profiles.filter(p=>{return p._id!=profile._id})[0]" :size="200" :ratio="1.3"
                class="rounded-4 position-absolute top-0 start-0"
                style="transform:translate(30%, 10%) rotate(-5deg)"
                />
            <ProfileMainPhotoComponent :profile="thisIsATwist.twists.filter(t=>{return t.profileIds.indexOf(profile._id)<0})[0].profiles[0]"  :size="200" :ratio="1.3"
                class="rounded-4 position-absolute top-0 start-50"
                style="transform: translate(-30%, 40%) rotate(10deg); border:1px white solid"
                />
            <ProfileMainPhotoComponent :profile="thisIsATwist.twists.filter(t=>{return t.profileIds.indexOf(profile._id)<0})[0].profiles[1]"  :size="200" :ratio="1.3"
                class="rounded-4 position-absolute top-0 start-50"
                style="transform:translate(-75%, 80%) rotate(-15deg); border:1px white solid"
                />
                <div style="height:500px"></div>
                <h2 class="text-center twist" >C'est un Twist !</h2>
                <div class="text-center">
                    Just Twist and Chill
                </div>
                <a href="javascript:void(0)" @click="()=>{router.push('/messages')}" class="btn btn-primary mt-3 d-block">Envoyer un message</a>
                <a href="javascript:void(0)" @click="thisIsATwist=null" class="btn btn-primary-outline mt-3 d-block twist fw-bold" style="padding-top:1rem; padding-bottom:1rem">Continuer à Twister</a>
        </div>
        <!--mes twists a valider-->
        <div v-if="pending && pending.length>0 && thisIsATwist==null" class=" mt-3">

            <div v-for="like, likeIndex in pending" :key="likeIndex"
                style="background-color: #FAECE3;overflow: hidden;"
                class="rounded-4 p-3 position-relative mb-2"
                :class="{'blury':likeIndex>0, 'shadow':likeIndex==0}"
                
                >
                <div
                    @click="handleViewTwistOfLike(like, true)"
                    :data-bs-toggle="likeIndex>0?'':'modal'" :data-bs-target="likeIndex>0?'':'#exampleModalViewTwist'"
                >
                    <div class="row" >
                        <div v-for="twist, twistIndex in like.twists" :key="twistIndex" class="col-6 container-pair d-flex justify-content-center ">
                            <div class="rounded-circle overflow-hidden profile"
                                :class="{'ANSWERED': like.validationByProfiles.filter(l=>l.profileId==twist.profiles[0]._id).length>0,'ms-1':twistIndex>0}"
                                >
                                <ProfileMainPhotoComponent :profile="twist.profiles[0]" :size="70" class="rounded-circle" />
                            </div >
                            <div class="rounded-circle ms-1 overflow-hidden profile"
                                :class="{'ANSWERED': like.validationByProfiles.filter(l=>l.profileId==twist.profiles[1]._id).length>0}">
                                <ProfileMainPhotoComponent :profile="twist.profiles[1]" :size="70" class="rounded-circle" />
                            </div>
                            <!-- <div class="border-end ms-1" v-if="twistIndex==0"></div> -->

                        </div>
                    </div>
                    <div class=" row mt-1" >
                        <div v-for="twist, twistIndex in like.twists" :key="twistIndex" class="col-6 container-pair-name d-flex justify-content-center">
                            <div class="overflow-hidden text-center" style="width:80px"
                                :class="{
                                    'is_me':twist.profiles[0].firstName==profile.firstName,
                                    'ms-1':twistIndex>0
                                    }"
                                >
                                {{ twist.profiles[0].firstName==profile.firstName?'': twist.profiles[0].firstName}}
                            </div >
                            <div class="ms-1 overflow-hidden  text-center" style="width:80px"  :class="{'is_me':twist.profiles[1].firstName==profile.firstName}">
                                {{ twist.profiles[1].firstName==profile.firstName?'': twist.profiles[1].firstName}}
                            </div >
                            <!-- <div class="border-end ms-1" v-if="twistIndex==0"></div> -->

                        </div>
                    </div>
                    <!-- <div style="background-color: #FDDAC2;height:100%;min-width:30px" class="position-absolute top-50 end-0 fs-1 translate-middle-y">
                        <i class="bi bi-chevron-compact-right position-absolute top-50 end-0 fs-1 translate-middle-y"></i>    
                    </div> -->
                </div>


                <!-- <a href="javascript:void(0)" @click="handleRejectLike(like)"  v-if="likeIndex==0"
                class="text-danger rounded-circle border-1 border shadow position-absolute top-100 start-50 "
                style="background-color: white;
        font-size: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        transform: translate(-150%, -40%);
        ">
                    <i class="bi-x "></i>
                </a>
                <a href="javascript:void(0)" @click="handleAcceptLike(like)" v-if="likeIndex==0"
                class="twist rounded-circle border-1 border shadow position-absolute top-100 start-50 "
                style="background-color: white;
        font-size: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        transform: translate(75%, -40%);
        ">
                    <i class="bi-check-all"></i>
                </a> -->
            </div>
        </div>
        <!--les twists a valider par un de mes pairs-->
        <div v-if="pendingMyPair && pendingMyPair.length>0 && thisIsATwist==null" class=" mt-3">
            <hr />
            <h5 class="text-center fw-bold">En <span class="twist">attente</span> de ta paire</h5>
            <div v-for="like, likeIndex in pendingMyPair" :key="likeIndex"
                style="background-color: #FAECE3;"
                class="rounded-4 p-3 position-relative mb-2 "
                @click="handleViewTwistOfLike(like, false)"
                data-bs-toggle="modal" data-bs-target="#exampleModalViewTwist"
                >
                <div class="row" >
                    <div v-for="twist, twistIndex in like.twists" :key="twistIndex" class="col-6 container-pair d-flex justify-content-center ">
                        <div class="rounded-circle overflow-hidden profile"
                            :class="{'ANSWERED': like.validationByProfiles.filter(l=>l.profileId==twist.profiles[0]._id).length>0,'ms-1':twistIndex>0}"
                            >
                            <ProfileMainPhotoComponent :profile="twist.profiles[0]" :size="70" class="rounded-circle" />
                        </div >
                        <div class="rounded-circle ms-1 overflow-hidden profile"
                            :class="{'ANSWERED': like.validationByProfiles.filter(l=>l.profileId==twist.profiles[1]._id).length>0}">
                            <ProfileMainPhotoComponent :profile="twist.profiles[1]" :size="70" class="rounded-circle" />
                        </div>
                    </div>
                </div>
                <div class=" row mt-1" >
                    <div v-for="twist, twistIndex in like.twists" :key="twistIndex" class="col-6 container-pair-name d-flex justify-content-center">
                        <div class="overflow-hidden text-center" style="width:80px"
                            :class="{
                                'is_me':twist.profiles[0].firstName==profile.firstName,
                                'ms-1':twistIndex>0
                                }"
                            >
                            {{ twist.profiles[0].firstName}}
                        </div >
                        <div class="ms-1 overflow-hidden  text-center" style="width:80px" 
                            :class="{'is_me':twist.profiles[1].firstName==profile.firstName}">
                            {{ twist.profiles[1].firstName}}
                        </div >
                        <!-- <div class="border-end ms-1" v-if="twistIndex==0"></div> -->

                    </div>
                </div>

            </div>
        </div>


        <!-- Modal view twist-->
        <div class="modal fade" data-bs-backdrop="static"  id="exampleModalViewTwist" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
                <div class="modal-content">
                <div class="modal-header" style="border-bottom:2px #F27721 solid">
                    <div class="modal-title fs-5 fw-bold">
                        <span v-if="canValidateTwist==true">Tu <span class="twist"> valides</span> ?</span>
                        <span v-if="canValidateTwist==false">En attente de ta paire</span>
                    </div>
                    <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> -->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body position-relative">
                    <div v-if="selectedTwist ">
                        <TwistComponent :twist="selectedTwist"></TwistComponent>
                    </div>
                    <div v-if="canValidateTwist==true">
                                  
                        <a href="javascript:void(0)" @click="handleRejectLike()"  v-if="selectedTwist"
                            data-bs-dismiss="modal"
                            class="text-danger rounded-circle border-1 border shadow zoom-btn "
                            style="font-size: 3rem;
                            position: fixed;
                            bottom: 2rem;
                            left: 1rem;
                            background: white;
                            padding-left: 0.75rem;
                            padding-right: 0.75rem;
                            ">
                            <!-- <i class="bi-x "></i> -->
                            <svg width="50" height="50" style="margin-top:-6px" viewBox="0 0 350 357" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.5174 93.4706C97.1973 87.6699 106.406 87.6699 112.086 93.4706L174.522 157.235L236.959 93.4706C242.639 87.6699 251.847 87.6699 257.527 93.4706C263.207 99.2713 263.207 108.676 257.527 114.477L195.091 178.242L257.527 242.006C263.207 247.807 263.207 257.212 257.527 263.012C251.847 268.813 242.639 268.813 236.959 263.012L174.522 199.248L112.086 263.012C106.406 268.813 97.1973 268.813 91.5174 263.012C85.8376 257.212 85.8376 247.807 91.5174 242.006L153.954 178.242L91.5174 114.477C85.8376 108.676 85.8376 99.2713 91.5174 93.4706Z" fill="#F14C4C" stroke="#F14C4C" stroke-width="28.258" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                        </a>
                        <a href="javascript:void(0)" @click="handleAcceptLike()" v-if="selectedTwist"
                            data-bs-dismiss="modal"
                            class="twist rounded-circle border-1 border shadow zoom-btn "
                            style="background-color: white;
                            font-size: 4rem;
                            position: fixed;
                            bottom: 2rem;
                            right: 1rem;
                            background: white;
                            padding-left: 0.75rem;
                            padding-right: 0.75rem;
                                                    ">
                            <!-- <i class="bi-check-all"></i> -->
                            <svg width="70" height="70" style="margin-top:-6px" viewBox="0 0 629 629" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M351.683 194.683C357.265 189.101 364.243 186.31 372.617 186.31C380.99 186.31 387.968 189.101 393.55 194.683C399.133 200.266 401.924 207.243 401.924 215.617C401.924 223.99 399.133 230.968 393.55 236.551L236.547 431.234C230.965 438.212 223.987 441.701 215.614 441.701C207.24 441.701 200.262 438.91 194.68 433.328L92.1046 328.659C82.3356 321.681 79.1955 312.261 82.6845 300.399C86.1734 288.536 93.5002 281.209 104.665 278.418C115.83 275.627 124.901 278.418 131.879 286.792L215.614 370.527L351.683 196.777V194.683ZM316.095 397.74L351.683 433.328C357.265 438.91 364.243 441.701 372.617 441.701C380.99 441.701 387.968 438.212 393.55 431.234L550.553 236.551C558.927 228.177 561.718 218.408 558.927 207.243C556.136 196.079 548.809 189.101 536.946 186.31C525.084 183.519 515.664 187.008 508.686 196.777L372.617 370.527L353.776 349.593L316.095 397.74Z" fill="#F27721"/>
</svg>

                        </a>
                    </div>
                </div>
  

                </div>
            </div>
        </div>

        
     
    </div>
</template>
<script setup>
import TwistComponent from './components/TwistComponent.vue'
import ProfileMainPhotoComponent from './components/ProfileMainPhotoComponent.vue'
import { useLikesStore} from "./stores/likes";
import { useProfileStore} from "./stores/profiles";
import { onMounted, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTokenStore } from "./stores/tokens";
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
//const twistsStore = useTwistsStore();
const likesStore= useLikesStore();
const tokenStore= useTokenStore();
const profileStore=useProfileStore();
const router = useRouter();
const pending= computed(()=>{
    return likesStore.pendingValidation;
});
const pendingMyPair= computed(()=>{
    return likesStore.pendingMyPairValidation;
});
const profile= computed(()=>{
    return profileStore.profile;
});
// const validated= computed(()=>{
//     return twistsStore.validated;
// });
const selectedTwist=ref(null);
const canValidateTwist=ref(false);
const handleViewTwistOfLike=(like,canValidate)=>{
    selectedTwist.value=like.twists.filter(t=>t.profileIds.indexOf(profile.value._id)<0)[0]
    canValidateTwist.value=canValidate
}
const thisIsATwist=ref(null)
const showThisIsATwist=(like)=>{
    thisIsATwist.value=like;
}
const handleAcceptLike= async(like)=>{
    if( like==null){
        like = pending.value[0];
    }
    /* A + B <=> C + D */
    for(var i=0; i< like.twists.length;i++){
        var twist= like.twists[i];
        if( twist.profileIds.indexOf(profile.value._id)<0){
            var response=await likesStore.iLike(twist._id);
            if( response.success && response.isNewMatchValidated==true){
                showThisIsATwist(like)
                //router.push("/messages");
            }
        }
    }
    /* A + B <=> B + C */
    if( like.twists[0].profileIds.indexOf(profile.value._id)>=0 && like.twists[1].profileIds.indexOf(profile.value._id)>=0){
        var responseAB=await likesStore.iLike(like.twists[0]._id);
        if( responseAB.success && responseAB.isNewMatchValidated==true){
            //router.push("/messages");
            showThisIsATwist(like)
        }
        var responseBC=await likesStore.iLike(like.twists[1]._id);
        if( responseBC.success && responseBC.isNewMatchValidated==true){
            //router.push("/messages");
            showThisIsATwist(like)
        }
    }
    selectedTwist.value=null;
    likesStore.fetchPendingValidation();

}

const handleRejectLike=async (like)=>{
    if( like==null){
        like = pending.value[0];
    }
    await likesStore.unLike(like);
    selectedTwist.value=null;
    likesStore.fetchPendingValidation();
}

onMounted(() => {
    if( !tokenStore.isLoggedIn){

        router.push('/')
    }else{

        likesStore.fetchPendingValidation();
        likesStore.fetchPendingMyPairValidation();
        window.scrollTo(0,0);
        //likesStore.fetchValidated();
  }
})

</script>

<style scoped>
.container-pair .profile{
    border:3px orange dashed;
    padding:2px;
    min-width:80px
}
.container-pair .profile.ANSWERED{
    border:3px green solid;
}
.container-pair-name div{font-weight: bold;}
.container-pair-name div.is_me{font-style: italic;font-weight: normal;}
.blury{
    filter:blur(0.3rem)
}
</style>