<template>
    
    <div class="row mt-3">
        
        <TwistPhotoGroupComponent :twist="twist" :myPair="myPair" class="col-3 position-relative" style="height:70px;margin-top:5px; margin-bottom:5px"></TwistPhotoGroupComponent>
        <!-- <div class="col-3 position-relative" style="height:80px">
            
            <ProfileMainPhotoComponent :profile="twist.profiles[1]" :size="60" class="position-absolute rounded-circle top-50 start-0"
                style="transform: translate(75%,-50%)!important;border:1px white solid"
            ></ProfileMainPhotoComponent>
            <ProfileMainPhotoComponent :profile="twist.profiles[0]" :size="60" class="position-absolute rounded-circle top-50 start-0"
                style="transform: translate(10%,-50%)!important;border:1px white solid"
            ></ProfileMainPhotoComponent>
        </div> -->
        <div class="col-9 border-bottom">
            
            <div class="fw-bold mt-3 text-capitalize">
                {{ twist.profiles[0].firstName }} & {{ twist.profiles[1].firstName }} 
                <span class="float-end badge rounded-pill bg-danger" v-if="nbUnreadMessages">{{ nbUnreadMessages }}</span>
            </div>
            <div v-if="lastMessage==null">Lance la discussion !</div>
            <div v-if="lastMessage" ><span class="text-truncate" style="display:inline-block;max-height:1.5rem;max-width: 200px;overflow: hidden;">{{lastMessage.text}}</span> <span class="float-end">{{ formatDate(lastMessage.date) }} <span class="text-danger" v-if="blocked && blocked==true">Bloqué</span></span></div>
        </div>    
        
    </div>
</template>
<script setup>
// import ProfileMainPhotoComponent from './ProfileMainPhotoComponent.vue'
import TwistPhotoGroupComponent from './TwistPhotoGroupComponent.vue'
import {defineProps, ref} from "vue";
const props = defineProps(['twist', 'lastMessage','myPair','nbUnreadMessages', 'blocked'])
const twist=ref(props.twist);
const lastMessage= ref(props.lastMessage)
const blocked= ref(props.blocked)
const nbUnreadMessages=ref(props.nbUnreadMessages)
const myPair= ref(props.myPair)
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const formatDate=(dateString)=>{
    //console.log('formatDate', dateString)
    const date = dayjs(dateString);
    //console.log('date', date);
        // Then specify how you want your dates to be formatted
    //return date.format('dddd MMMM D, YYYY');
    return date.fromNow()
}
</script>