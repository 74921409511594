<style scoped>

        .container-twist{
          transition:transform 0.5s ease-in-out;
        }
        .container-twist.like{
          transform: translate(500px,0);
        }
        .container-twist.dontlike{
          
          transform: translate(-500px,0);
        }
</style>
<template>
  <div>
    {{ search_error }}
    <div style="text-align:center" class="position-relative">
      <img src="twist_logo.png" style="height:40px" />
      <a 
        href="javascript:void(0)" @click="()=>{router.push('/search-preferences')}"
        class="position-absolute top-50 end-0 translate-middle-y btn btn-outline-primary"
        ><i class="bi-sliders2"></i></a>
    </div>

      <div v-for="twist, twistIndex in twists" :key="twistIndex">
        <div v-if="viewTwistIndex==twistIndex" class="fw-bolder">
          <div class="container-twist" :class="{'like':animateLikeTwist=='like','dontlike':animateLikeTwist=='dontlike' }">
            <TwistComponent :twist="twist"></TwistComponent>
          </div>
          <div style="height:4rem"></div>

          <a href="javascript:void(0)" @click="dontLikeTwist(twist)" class="zoom-btn text-danger rounded-circle border-1 border shadow"
            style="font-size: 3rem;
    position: fixed;
    bottom: 5rem;
    left: 1rem;
    background: white;
    padding-left: 1rem;
    padding-right: 1rem;
    "
          >
            <!-- <i class="bi-x "></i> -->
            <svg width="40" height="40" style="margin-top:-6px" viewBox="0 0 350 357" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.5174 93.4706C97.1973 87.6699 106.406 87.6699 112.086 93.4706L174.522 157.235L236.959 93.4706C242.639 87.6699 251.847 87.6699 257.527 93.4706C263.207 99.2713 263.207 108.676 257.527 114.477L195.091 178.242L257.527 242.006C263.207 247.807 263.207 257.212 257.527 263.012C251.847 268.813 242.639 268.813 236.959 263.012L174.522 199.248L112.086 263.012C106.406 268.813 97.1973 268.813 91.5174 263.012C85.8376 257.212 85.8376 247.807 91.5174 242.006L153.954 178.242L91.5174 114.477C85.8376 108.676 85.8376 99.2713 91.5174 93.4706Z" fill="#F14C4C" stroke="#F14C4C" stroke-width="28.258" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          </a>&nbsp;
          <a href="javascript:void(0)" @click="likeTwist(twist)" class="zoom-btn twist rounded-circle border-1 border shadow"
            style="font-size: 4rem;
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    background: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;">
            <!-- <i class="bi-check-all"></i> -->
            <svg width="70" height="70" style="margin-top:-6px" viewBox="0 0 629 629" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M351.683 194.683C357.265 189.101 364.243 186.31 372.617 186.31C380.99 186.31 387.968 189.101 393.55 194.683C399.133 200.266 401.924 207.243 401.924 215.617C401.924 223.99 399.133 230.968 393.55 236.551L236.547 431.234C230.965 438.212 223.987 441.701 215.614 441.701C207.24 441.701 200.262 438.91 194.68 433.328L92.1046 328.659C82.3356 321.681 79.1955 312.261 82.6845 300.399C86.1734 288.536 93.5002 281.209 104.665 278.418C115.83 275.627 124.901 278.418 131.879 286.792L215.614 370.527L351.683 196.777V194.683ZM316.095 397.74L351.683 433.328C357.265 438.91 364.243 441.701 372.617 441.701C380.99 441.701 387.968 438.212 393.55 431.234L550.553 236.551C558.927 228.177 561.718 218.408 558.927 207.243C556.136 196.079 548.809 189.101 536.946 186.31C525.084 183.519 515.664 187.008 508.686 196.777L372.617 370.527L353.776 349.593L316.095 397.74Z" fill="#F27721"/>
</svg>

          </a>
        </div>
      </div>
      <div v-if="twists.length==0" class="text-center " style="margin-top:200px">
        <div class="fs-3">Plus de Twists dans le coin...</div>
        <div class="mt-5"> Modifier mes <a href="javascript:void(0)" @click="()=>{router.push('/search-preferences')}">préférences de recherche</a></div>
      </div>
  </div>
</template>
<script setup>
import { useTokenStore } from "./stores/tokens";
import { useRouter } from 'vue-router'
import TwistComponent from './components/TwistComponent.vue'
import { useSearchStore } from "./stores/search";
import { useLikesStore } from "./stores/likes";
import {  onMounted, computed, ref} from "vue";
const searchStore = useSearchStore();
const likesStore= useLikesStore();
const tokenStore= useTokenStore();
const router = useRouter();
const twists = computed(() => {
  return searchStore.twists;
});

const search_error=computed(()=>{
  return searchStore.search_error;
})

const animateLikeTwist=ref('')
const viewTwistIndex=ref(0);
const dontLikeTwist=async (twist)=>{
  animateLikeTwist.value='dontlike'

  await likesStore.iDontLike(twist._id);
  next();
  animateLikeTwist.value=''
}
const likeTwist=async (twist)=>{
  animateLikeTwist.value='like'
  let response=await likesStore.iLike(twist._id);
  if( response.success){
    if( response.isNewMatch==true){
      alert('Vous y êtes presque ! Rendez-vous dans "Tu Valides" pour finaliser votre TWIST.');
    }
    next();
    
  }else{
    if (response.reason=="USER_PROFILE_HAS_NO_TWIST") {
      window.alert('Ajoute une paire pour commencer à Twister')
      router.push('/invitation')
    }

  }
  animateLikeTwist.value=''
  
}
const scrollTop=()=>{
  window.scrollTo(0,0);
}
const next=()=>{
  if( viewTwistIndex.value<twists.value.length-1){
    viewTwistIndex.value++;
  }else{
    searchStore.search();
    viewTwistIndex.value=0;
  }
  scrollTop()
}

onMounted(() => {
  if( !tokenStore.isLoggedIn){
    router.push('/')
  }else{
    searchStore.search();
    scrollTop()
  }
});
</script>