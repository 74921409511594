<template>
    <div>Loggin out...</div>
</template>
<script setup>
import { useTokenStore} from "./stores/tokens";
import { onMounted } from 'vue'
import {  useRouter } from 'vue-router'
const tokenStore = useTokenStore();
const router = useRouter()
onMounted(() => {
    tokenStore.logout();
    router.push("/")
})
</script>