<style scoped>
#app h5{display: inline;font-size:16px}
#app span.float-end{
  /* transform:translate(0px, -15px); */
  color:#F27721;font-size:18px
}
.row > .col-4:not(:first-child) {
padding-left:6px
}
.row > .col-4:not(:last-child) {
padding-right:6px
}
</style>
<template>
  <div>
    <div style="text-align:center" class="position-relative">
      <img src="twist_logo.png" style="height:40px" @click="router.push('/onboarding')" />

      <div class="position-absolute top-0 end-0" >
            <div class="btn-group dropstart">
            <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="javascript:void(0)" @click="handleLogoutRequested()">Se déconnecter </a></li>
                <li><a class="dropdown-item" href="javascript:void(0)" @click="handleDeleteProfile()">Supprimer mon profil</a></li>
            </ul>
            </div>
        </div>

    </div>
    
    <h1 class="row">
      <div class="col-6">Mon profil</div>
      <div class="col-6 " style="padding-top:10px"> 
        <div class="progress position-relative" role="progressbar" aria-label="Example with label" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar overflow-visible " :style="'width:'+profileCompletness()+'%;'" style="background-color: rgb(250, 236, 227);"></div>
          <div class="position-absolute top-0 start-50 translate-middle-x " style="color:#F27721;white-space:nowrap;">Complété à {{profileCompletness()}}%</div>
        </div>
      </div>
      <!-- <div class="float-end" style="font-size:12px;color:gray;transform:translate(00px, 15px)"
      data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='nickName'"># Twister ID: {{ profile.nickName }} <i class="bi bi-chevron-compact-right"></i></div> -->
      <!-- <a href="javascript:void(0)" @click="profilEdition=true" v-if="profilEdition==false" class="float-end twist btn btn-outline-primary"><i class="bi bi-pencil"></i></a>
      <a href="javascript:void(0)" @click="profilEdition=false" v-if="profilEdition==true" class="float-end twist btn btn-outline-primary"><i class="bi bi-arrow-left"></i></a> -->
    </h1>
    
    <div class="mb-3 ">
      <div class="position-relative" style="height: 200px;">
        <div class="position-absolute top-50 start-50 translate-middle">
          
          
          <a href="javascript:void(0)" 
            id="a_mainPhoto"
            @click="handleMainPhotoClick"
            data-bs-toggle="modal" data-bs-target="#exampleModal"
             >
            <ProfileMainPhotoComponent :profile="profile" :size="200" class="rounded-5"></ProfileMainPhotoComponent>
          </a>
        </div>

      </div>
      
      
    </div>
    


    <div class="row mb-3 ">
      <div v-for="i in [1,2,3]" :key="i" class="col-4 position-relative">
        
        <a href="javascript:void(0)" 
          @click="()=>{photoType=i;}"
          data-bs-toggle="modal" data-bs-target="#exampleModal"
          >
          <ProfilePhotoComponent :profile="profile" :index="i" :height="180" class="rounded-3 mx-auto "></ProfilePhotoComponent>
        </a>
<!-- 
        <div class="position-absolute top-0 left-0 text-center" v-if="(profile.photos||[]).length>i" 
          style="background-color:#FFFFFF66;">
          <a href="javascript:void(0)" style="color:black;max-height:3rem;overflow:hidden;display:block;margin-right:12px" 
            @click="()=>{selectedPhotoIndex=i}"
            data-bs-toggle="modal" data-bs-target="#exampleModalDescPhoto"
            >
              {{ (profile.photos_description||[])[i]?'Description ajoutée':'Ajoute une description' }}
              
          </a>
        </div> -->
      </div>
      
    </div>

    <h2 class="mt-4">Mes intérêts</h2>

    <div class="mt-2 pt-2 "  data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='city'" id="div_city">
      <h5>Ville</h5>
      <div  class="float-end">
        {{ profile.city||'Ajouter' }} 
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='searchingFor'" id="div_searchingFor">
      <h5>Je recherche</h5>
      <div  class="float-end">
        {{ profile.searchingFor||'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>

    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='searchingForGender'" id="div_searchingForGender">
      <h5>Préférence</h5>
      <div  class="float-end">
        {{ genders[profile.searchingForGender] || 'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>

    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='currentPosition'">
      <h5>Activité</h5>
      <div class="float-end">
        {{ profile.currentPosition || 'Ajouter'}} 
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalHobbies">
      <h5>Hobbies</h5>  
      <div  class="mb-3 float-end">
        <span v-if="(profile.hobbies||[]).length==0" class="mb-3 text-center">
          Ajouter
        </span>
        <span>{{  (profile.hobbies||[]).join(', ') }}</span>
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>


    <div style="clear:both"></div>
    <h2 class="mt-3">Mes informations</h2>

    <div  data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='gender'" id="div_gender">
      <h5>Je suis</h5>
      <div class="float-end">
        {{ genders[profile.gender] }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='firstName'" id="div_firstName">
      <h5>Prénom</h5>
      <div class="float-end">
        {{ profile.firstName||'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='age'" id="div_age">
      <h5>Age</h5>
      <div class="float-end">
        {{ profile.age }} ans
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='height'" id="div_height">
      <h5>Taille</h5>
      <div class="float-end">
        {{ profile.height }} cm
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
      <div style="clear:both"></div>
    </div>

    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalLanguages">
      <h5>Langues</h5>  
      <div class=" float-end">
        <span v-if="(profile.languages||[]).length==0" class="mb-3 text-center">
          Ajouter
        </span>
        <span v-if="profile.languages" class="mb-3">
          {{ profile.languages.reduce((c,i)=>{c.push(htLanguages[i]); return c;},[]).join(', ') }}
          
        </span>
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    <div style="clear:both"></div>
    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='astrologicalSign'">
      <h5>Signe astrologique</h5>
      <div  class="float-end">
        {{profile.astrologicalSign||'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
      
      
    </div>

    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='smoking'">
      <h5>Fumeur</h5>
      <div  class="float-end">
        {{ labelsYN[profile.smoking]||'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>

    <div class="mt-2 pt-2 border-top" data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='alcohol'">
      <h5>Alcool</h5>
      <div  class="float-end">
        {{ labelsYN[profile.alcohol]||'Ajouter' }}
        <span class="float-end"><i class="bi bi-chevron-compact-right"></i></span>
      </div>
    </div>
    
    <div style="clear:both"></div>
    <p class="text-center mt-2 fst-italic">
      Un problème ? <a href="mailto:contact@twistsapp.com" class="btn btn-link twist">Contacter Twist</a>
    </p>
    
  <!-- Modal edit profile fields-->
  <div class="modal fade" id="exampleModalEdit" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-bottom" style="align-items:flex-end">
      <div class="modal-content overflow-hidden rounded-4">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Mon profil <span class="twist">Twist</span></h1>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">

          <div  v-if="editField=='gender'">

            <div class="mb-4">Comment te définis-tu ?</div>
            <div class="d-flex flex-column flex-md-row gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in ['M', 'F', 'X']" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.gender=text" :checked="profile.gender==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{ genders[text] }}</span>
                  </label>
                </div>
              </div>
            </div>

           
          </div>

          <div v-if="editField=='firstName'">
            <div class="mb-4">Indique ton nom d'utilisateur </div>  
            <div class="form-floating mb-4" >
              <input type="text" class="form-control" id="floatingInput" placeholder="Prénom" v-model="profile.firstName" >
              <label for="floatingInput">Prénom</label>
            </div>      
          </div>

          <div v-if="editField=='age'">
            <div class="mb-4">Quel age as-tu ? </div>  
            <div class="form-floating mb-4" >
              <input type="number" class="form-control" id="floatingInput" placeholder="Age" v-model="profile.age" >
              <label for="floatingInput">Age (ans)</label>
            </div>
          </div>

          <div v-if="editField=='height'">
            <div class="mb-4">Indique ta taille </div>  
            <div class="form-floating mb-4" >
              <input type="number" class="form-control" id="floatingInput" placeholder="Taille" v-model="profile.height" >
              <label for="floatingInput">Taille (cm)</label>
            </div>
          </div>


          <div v-if="editField=='city'">
            <div class="mb-4">Dans quelle ville habites-tu la majeure partie de l'année ?</div>  
            <div class="mb-4 form-floating position-relative" > 
              <div class="input-group mb-3">
                <input type="text" class="form-control" id="floatingInput" autocomplete="off"  placeholder="Ville" v-model="profile.city" @keyup="handleCityKeyUp" @blur="handleOnBlur">
                  
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                  v-if="locationEnabled" @click="handleCurrentLocation"
                ><i class="bi bi-geo-alt" ></i></button>
              </div>
              <label for="floatingInput">Ville</label>
              
              <!-- <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button> -->
              <ul class="list-group" v-if="cities_matched && cities_matched.length>0 && displaySearchCities">
                <li  v-for="match, matchIndex in cities_matched" :key="matchIndex" class="list-group-item">
                  <a class="dropdown-item" href="javascript:void(0)" @click="selectLocation(match)">{{match.label}} - {{match.zip_code}} - {{match.department_name}}</a>
                </li>
              </ul>
            </div>
          </div>

          <div v-if="editField=='nickName'">
            <div class="mb-4">Renseigne ton <span class="twist">#TwisterId</span> pour que tes amis te retrouvent plus facilement</div>  
            <div class="form-floating mb-4" >
              <input type="text" class="form-control" id="floatingInput" placeholder="Activité" v-model="profile.nickName" >
              <label for="floatingInput"><i class="bi bi-hash"></i> Mon Twister ID</label>
            </div>
          </div>

          <div v-if="editField=='currentPosition'">
            <div class="mb-4">Tu as un job passionnant, tu es étudiant en grec ancien ? Indique ton activité en ce moment</div>  
            <div class="form-floating " >
              <input type="text" class="form-control" id="floatingInput" placeholder="Activité" v-model="profile.currentPosition" >
              <label for="floatingInput"><i class="bi bi-briefcase"></i> Activité</label>
            </div>
          </div>
          <div v-if="editField=='searchingFor'">
            <div >Quel type de rencontre recherches-tu en ce moment ?</div>  
          
            <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in ['Relation longue durée', 'Relation courte', 'De simples rencontres', 'Je ne sais pas']" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.searchingFor=text" :checked="profile.searchingFor==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{ text }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>


          <div class="mb-4" v-if="editField=='searchingForGender'">
            <div >Qui as tu envie de rencontrer ?</div>  
            <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in ['M', 'F', 'X', 'A']" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.searchingForGender=text" :checked="profile.searchingForGender==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{ genders[text] }}</span>
                  </label>
                </div>
              </div>
            </div>
            
          </div>


              
          <div v-if="editField=='astrologicalSign'">
            <div>Renseigne ton signe astrologique</div>
            <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in astrologicalSigns" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.astrologicalSign=text" :checked="profile.astrologicalSign==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{ text }}</span>
                  </label>
                </div>
              </div>
            </div>

          </div>

          <div  v-if="editField=='smoking'">
            <div>Tu fumes ?</div>
            <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in ['y','n','s']" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.smoking=text" :checked="profile.smoking==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{labelsYN[ text] }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          
          <div class="form-floating mb-4" v-if="editField=='alcohol'">
            <div>Tu bois...  un peu , beaucoup, passionnément ou pas du tout ?</div>
            <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
              <div class="list-group list-group-radio d-grid gap-2 border-0 w-75">
                <div class="position-relative" v-for="text, index in ['y','n','s']" :key="text">
                  <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" :id="'listGroupRadioGrid'+index" value="" @click="profile.alcohol=text" :checked="profile.alcohol==text">
                  <label class="list-group-item py-3 pe-5" :for="'listGroupRadioGrid'+index">
                    <!-- <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong> -->
                    <span class="d-block small opacity-75">{{labelsYN[ text] }}</span>
                  </label>
                </div>
              </div>
            </div>
            
          </div>


        </div>
        <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
            @click="profileStore.fetchProfile()"
          >
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
                     @click="enregistrerUserClick"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>
      </div>
    </div>
  </div>



<!--     
    <div class="mb-3 mt-5">
      <a href="javascript:void(0)" class="btn d-block btn-primary " @click="enregistrerUserClick">Enregistrer</a>
    </div> -->

  <!-- Modal hobbies -->
  <div class="modal fade" data-bs-backdrop="static"  id="exampleModalHobbies" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Mes Hobbies {{ (profile.hobbies||[]).length }} <b>/</b> {{ nbMaxHobby }}</h1>
              
          </div>
          <div class="modal-body" >
              
            <div class="mb-3 mt-3">
            
              <span v-for="hobby, hobbyIndex in hobbies" :key="hobbyIndex" >
                <a href="javascript:void(0)" @click="toggleHobby(hobby)" class="btn me-3 mb-3" :class="{'bg-primary text-white': (profile.hobbies||[]).indexOf(hobby.label)>=0,'bg-light': (profile.hobbies||[]).indexOf(hobby.label)<0}">
                  <i  :class="('bi bi-'+ hobby.img)"></i> {{ hobby.label }}
                </a>
              </span> 
            </div>
              
          </div>
          <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
            @click="profileStore.fetchProfile()"
          >
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
                     @click="enregistrerUserClick"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>

        </div>
    </div>
  </div>

  <!-- Modal languages -->
  <div class="modal fade" data-bs-backdrop="static"  id="exampleModalLanguages" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Mes Langues 
                <!-- {{ (profile.languages||[]).length }} <b>/</b> {{ nbMaxLanguage }} -->
              </h1>
              
          </div>
          <div class="modal-body" >
              
            <div class="mb-3 mt-3">
            
              <span v-for="language, languageIndex in languages" :key="languageIndex" >
                <a href="javascript:void(0)" @click="toggleLanguage(language)" class="btn me-3 mb-3" :class="{'bg-primary text-white': (profile.languages||[]).indexOf(language.code)>=0,'bg-light': (profile.languages||[]).indexOf(language.code)<0}">
                  {{language.label }}
                </a>
              </span> 
            </div>
              
          </div>
          <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
            @click="profileStore.fetchProfile()"
          >
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
                     @click="enregistrerUserClick"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>

        </div>
    </div>
  </div>



    <!-- Modal description photo-->
    <div class="modal fade" data-bs-backdrop="static"  id="exampleModalDescPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content " >
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Mets en avant ta photo !</h1>
                <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
            </div>
            <div class="modal-body" >
                <div v-for="topicByCategory, topicByCategoryIndex in topicsByCategory" :key="topicByCategoryIndex" class="mb-3">
                    <h2 >{{ topicByCategory.label }}</h2>
                    <ul class="list-group list-group-flush">
                        
                        <li v-for="topic, topicIndex in topicByCategory.topics" :key="topicIndex" @click="handleSelectPhotoDesc(topic)" class="list-group-item"
                        :class="{'fw-bold list-group-item-secondary':(profile.photos_description||['','','',''])[selectedPhotoIndex]==topic}"
                        data-bs-toggle="modal" data-bs-target="#exampleModal"
                        >
                       
                            <span >{{ topic }}  </span>
                            <i class="bi bi-check float-end" v-if="(profile.photos_description||['','','',''])[selectedPhotoIndex]==topic"></i>
    
                        </li>
                    </ul>
                </div>
            
            </div>
        
            </div>
        </div>
    </div>
    



<!-- Modal photo-->
<div class="modal fade modal-fullscreen" data-bs-backdrop="static"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content rounded-4">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter une photo</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="()=>{newFile=''}"></button>
      </div>
      <!-- <div v-show="newFile">new file</div>
      <div v-show="!newFile">not new file</div> -->

      <div class="mx-auto mt-2" v-show="newFile">
        <div style="width:{{maxWidth}}px;height:{{maxWidth}}px;">
          <canvas id="canvas" style="width:100%;height:100%;" >
      
          </canvas>
        </div>
      </div>
        
    
        <a href="javascript:void(0)" class="mx-auto mt-2 mb-2" @click="()=>{inputFile.click();}"  v-show="!newFile">
          <ProfilePhotoComponent :profile="profile" :index="photoType" :size="maxWidth" :ratio="1" class="rounded-3"></ProfilePhotoComponent>
        </a>

        <div  v-if="photoType!='main'" 
          class="mx-auto" style="width:{{maxWidth}}px"
          >
          <a href="javascript:void(0)" style="overflow:hidden;display:block;" class="btn btn-secondary d-block mt-3 mb-3" 
            @click="()=>{selectedPhotoIndex=photoType}"
            data-bs-toggle="modal" data-bs-target="#exampleModalDescPhoto"
            >
              {{ (profile.photos_description||[])[photoType]??'Ajouter une description' }}
              <i class="bi bi-chevron-compact-right"></i>
          </a>
        </div>

      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" @click="uploadNewFile(photoType)" data-bs-dismiss="modal">Enregistrer</button>
      </div> -->
      <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
          @click="()=>{newFile=''}">
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
            @click="uploadNewFile(photoType)"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>
    </div>
  </div>
</div>







    
    <input class="form-control d-none" type="file" @change="selectFile" accept="image/*" ref="inputFile"/>

    <div style="display: none;">
      <img :src="newFile" @load="handleImgLoaded"  />
    </div>
    <!-- <div class="mb-3">
      <a href="javascript:void(0)" @click="uploadNewFile('main')"  class="btn d-block btn-primary ">Charger</a>
    </div> -->


  </div>
</template>
<script setup>

import { useProfileStore } from "./stores/profiles";
import { useCitiesStore } from "./stores/cities";
import { useTokenStore } from "./stores/tokens";
import {  onMounted, computed, ref, watch } from "vue";
import { useRouter } from 'vue-router'
import ProfileMainPhotoComponent from './components/ProfileMainPhotoComponent.vue'
import ProfilePhotoComponent from './components/ProfilePhotoComponent.vue'
const profileStore = useProfileStore();
const router = useRouter();
const nbMaxHobby=ref(5);
const nbMaxLanguage=ref(5);
const citiesStore=useCitiesStore();
const tokenStore= useTokenStore();
//const search_location=ref('');
const displaySearchCities=ref(false);
const profilEdition = ref(false);
const profile = computed(() => {
  //console.log("computed profile from profile")
  return profileStore.profile;
});

const editField=ref('')
// const mainPhoto = computed(() => {
//   return profileStore.main_photo;
// });
const handleSelectPhotoDesc=(description)=>{
  var photos_description=profile.value.photos_description||[];
  while( photos_description.length<profile.value.photos.length){
    photos_description.push('')
  }
  if(selectedPhotoIndex.value< photos_description.length){
    photos_description[selectedPhotoIndex.value]=description;
    profile.value.photos_description=photos_description
  }
  enregistrerUserClick();
  //console.log('photos_description', photos_description, description)
}
const selectedPhotoIndex=ref(0)
const genders=ref({'M':'Homme', 'F':'Femme', 'X':'Non-binaire', 'A':'Tout le monde'})
const labelsYN=ref({
    y:'Oui',
    n:'Non',
    s:'Parfois'
})
const topicsByCategory=ref([
{ label: 'Oups', topics: [
    "Mission impossible",
    "Essayer d'être cool, saison 1, épisode 1",
    "Je pensais être photogénique",
    "Tentative de photo artistique",
    "Essai de photo glamour",
    "Quand tu te rends compte que la caméra est allumée",
    "Je suis somnambule…",
    "Error 404",
    "Instagram vs réalité",
    "Quand tu as 2 visages…",
    "Tentative ratée",
    "Moi après 30 secondes de planche",
    "Je croyais que c'était mon bon profil",
    "C'était censé être une pose cool",
    "À moitié prêt"
  ]},
  { label: 'Spontané', topics: [
    "Surprise ! Ou pas...",
    "Ce moment gênant…",
    "Selfie spontané",
    "Quand tu réalises que c'est lundi",
    "Quand tu réalises que tu as oublié quelque chose",
    "Quand la lumière n'est pas de ton côté",
    "Quand tu entends ton morceau préféré",
    "Moi quand on me demande de faire 6x7",
    "Moi quand on me demande si je préfère la fêta ou les tomates…",
    "Le photobomber de l'année",
    "Moi et mon double menton",
    "j’ai réussi à rentrer",
    "C’était marrant jusqu’à ce que…"
  ]},
  { label: 'Au quotidien', topics: [
    "Jour de bad hair",
    "Quand le café n'a pas encore fait effet",
    "Réveil difficile",
    "Duckface activé",
    "Ma coiffure au réveil",
    "Haut chic, bas choc",
    "Le selfie après sport",
    "Moi en train de prendre le soleil",
    "Moi, version panda",
    "Moi quand on me dit soit prêt à 19h",
    "Je me suis levé(e) du pied gauche",
    "Moi quand je veux avoir l'air mystérieux(se)"
  ]},
  { label: 'Moments de Vie', topics: [
    "Bref, c’était une bonne soirée…",
    "Les joies du télétravail",
    "Moi après le 2ème verre",
    "J’ai toujours été fan de sangria",
    "Quand on me dit “Le bar est ouvert”",
    "Quand je demande à mes potes de me prendre en photo",
    "Rayonnant(e) comme toujours",
    "Quand tu te rends compte que la caméra est allumée",
    "That’s me. You’re probably wondering how I ended up in that situation."
  ]},
  { label: 'Stylé…', topics: [
    "Que je me mets sur mon 31",
    "La perfection incarnée",
    "Si je te regarde comme ça tu as tout gagné",
    "Éclatant(e) sous tous mes angles",
    "Dîner de gala... ou presque",
    "Quand la caméra capture ta vraie beauté",
    "Quand tu te trouves juste parfait(e)"
  ]},
  { label: 'Références', topics: [
    "Comment être votre blanquette",
    "La bedo me fait mal au crâne",
    "You can’t park there",
    "Y a pas de panneau",
    "Je ne crois pas qu’il y ait de bonne ou de mauvaise situation",
    "Mais t’es pas net Baptiste",
    "Bravo Nils, super la caméra",
    "Oh le matelas à grand papa",
    "Si le savoir est une arme et bah n*m",
    "Fais pas le cowboy, tu sens le monoy"
  ]}
])
const astrologicalSigns=ref([
'Bélier'
,'Taureau'
,'Gémeaux'
,'Cancer'
,'Lion'
,'Vierge'
,'Balance'
,'Scorpion'
,'Sagittaire'
,'Capricorne'
,'Verseau'
,'Poissons'
])
const languages=ref([
{ code: 'ar', label: 'Arabe' },
  { code: 'bn', label: 'Bengali' },
  { code: 'de', label: 'Allemand' },
  { code: 'en', label: 'Anglais' },
  { code: 'es', label: 'Espagnol' },
  { code: 'fa', label: 'Persan' },
  { code: 'fr', label: 'Français' },
  { code: 'gu', label: 'Gujarati' },
  { code: 'ha', label: 'Haoussa' },
  { code: 'hi', label: 'Hindi' },
  { code: 'id', label: 'Indonésien' },
  { code: 'it', label: 'Italien' },
  { code: 'ja', label: 'Japonais' },
  { code: 'jv', label: 'Javanais' },
  { code: 'ko', label: 'Coréen' },
  { code: 'km', label: 'Khmer' },
  { code: 'kn', label: 'Kannada' },
  { code: 'ml', label: 'Malayalam' },
  { code: 'mr', label: 'Marathi' },
  { code: 'ms', label: 'Malais' },
  { code: 'my', label: 'Birman' },
  { code: 'ne', label: 'Népalais' },
  { code: 'pa', label: 'Panjabi' },
  { code: 'pl', label: 'Polonais' },
  { code: 'pt', label: 'Portugais' },
  { code: 'ro', label: 'Roumain' },
  { code: 'ru', label: 'Russe' },
  { code: 'ta', label: 'Tamoul' },
  { code: 'te', label: 'Télougou' },
  { code: 'th', label: 'Thaï' },
  { code: 'tr', label: 'Turc' },
  { code: 'uk', label: 'Ukrainien' },
  { code: 'ur', label: 'Ourdou' },
  { code: 'vi', label: 'Vietnamien' },
  { code: 'yo', label: 'Yoruba' },
  { code: 'zh', label: 'Chinois' },
  { code: 'zu', label: 'Zoulou' }
])
const htLanguages=computed(()=>{
  return languages.value.reduce((c,i)=>{c[i.code]=i.label;return c;},{})
})
const hobbies= ref([
  {img:'',label:'Photographie'
},{img:'',label:'Shopping'
},{img:'',label:'Karaoké'
},{img:'',label:'Yoga'
},{img:'',label:'Cuisiner'
},{img:'',label:'Tennis'
},{img:'',label:'Courir'
},{img:'',label:'Nager'
},{img:'',label:'Art'
},{img:'',label:'Voyager'
},{img:'',label:'Sports extrêmes'
},{img:'',label:'Musique'
},{img:'',label:'Bars'
},{img:'',label:'Jeux vidéo'
},{img:'',label:'Lecture'
},{img:'',label:'Écriture'
},{img:'',label:'Jardinage'
},{img:'',label:'Bricolage'
},{img:'',label:'Randonnée'
},{img:'',label:'Cyclisme'
},{img:'',label:'Fitness'
},{img:'',label:'Danse'
},{img:'',label:'Couture'
},{img:'',label:'Poterie'
},{img:'',label:'Jeux de société'
},{img:'',label:'Camping'
},{img:'',label:'Pêche'
},{img:'',label:'Équitation'
},{img:'',label:'Basket'
},{img:'',label:'Football'
},{img:'',label:'Golf'
},{img:'',label:'Ski'
},{img:'',label:'Snowboard'
},{img:'',label:'Surf'
},{img:'',label:'Plongée'
},{img:'',label:'Escalade'
},{img:'',label:'Astronomie'
},{img:'',label:'Bénévolat'
},{img:'',label:'Théâtre'
},{img:'',label:'Cinéma'
},{img:'',label:'Vlog'
},{img:'',label:'Pâtisserie'
},{img:'',label:'Jouer aux cartes'
},{img:'',label:'Méditation'
},{img:'',label:'Sauna'
},{img:'',label:'Politique'
},{img:'',label:'Aviron'
},{img:'',label:'Marcher'
},{img:'',label:'Aquagym'
},{img:'',label:'Badminton'
},{img:'',label:'Athlétisme'
},{img:'',label:'Boxe'
},{img:'',label:'Mode'
},{img:'',label:'Crossfit'
},{img:'',label:'Salle de sport'
},{img:'',label:'Volleyball'
},{img:'',label:'Entrepreneuriat'
},{img:'',label:'Montagne'
},{img:'',label:'Immobilier'
},{img:'',label:'Bourse'
},{img:'',label:'Motos'
},{img:'',label:'Sneakers'
},{img:'',label:'Kite surf'
},{img:'',label:'Vin'
},{img:'',label:'Arts martiaux'
},{img:'',label:'Padel'
},{img:'',label:'Anime'
},{img:'',label:'Comédie'
},{img:'',label:'BBQ'
},{img:'',label:'Podcasts'
},{img:'',label:'Backpack'
},{img:'',label:'Tir à l’arc'
},{img:'',label:'Tennis de table'
},{img:'',label:'Chasse'
},{img:'',label:'Plage'
},{img:'',label:'Automobile'
},{img:'',label:'Réseaux sociaux'
},{img:'',label:'Roadtrips'
},{img:'',label:'Rugby'
},{img:'',label:'Brunch'}
]);
const newFile= ref('');
const inputFile=ref(null);
const toggleHobby=(hobby)=>{
  if( profile.value.hobbies==null){
    profile.value.hobbies=[];
  }
  profile.value.hobbies= 
    [...profile.value.hobbies.reduce((c,i)=>
    {
      if( i!= hobby.label){c.push(i);} 
      return c;
    },[]),
    ...
    (profile.value.hobbies.indexOf(hobby.label)<0?[hobby.label]:[])].slice(0,nbMaxHobby.value);
    // profile.value.hobbies.reduce((c,i)=>
    // {
    //   if( i!= hobby.label){c.push(i);} 
    //   return c;
    // },
    // profile.value.hobbies.indexOf(hobby.label)<0?[hobby.label]:[]).slice(0,nbMaxHobby.value);
}

const toggleLanguage=(language)=>{
  if( profile.value.languages==null){
    profile.value.languages=[];
  }
  profile.value.languages= 
    [...profile.value.languages.reduce((c,i)=>{if( i!= language.code){c.push(i);} return c;},[]),
    ...(profile.value.languages.indexOf(language.code)<0?[language.code]:[])].slice(0,nbMaxLanguage.value);
  // profile.value.languages.reduce((c,i)=>{if( i!= language.code){c.push(i);} return c;},profile.value.languages.indexOf(language.code)<0?[language.code]:[]).slice(0,nbMaxLanguage.value);
}
const handleCityKeyUp=(e)=>{
  citiesStore.searchFor(profile.value.city);
  console.log(e)
  displaySearchCities.value=true;
}

const profileCompletness=()=>{
  var sum =0;
  var props=['city', 'searchingFor','searchingForGender','currentPosition', 'hobbies','gender', 'firstName','age','height','languages','astrologicalSign','smoking','alcohol']
  for(var prop in props)
  {
    if( profile.value[props[prop]] !=null && (profile.value[props[prop]].length>0 || profile.value[props[prop]]>0)){
      sum+=5;
    }
  }
  
  //13*5=65 + 3*10+15 
  var nbPhotos=(profile.value.photos||[]).length;
  if(nbPhotos>4){
    nbPhotos=4;
  }

  sum+= 5*nbPhotos;
  
  if( profile.value.main_photo){
    sum+=15;
  }
  
  // console.log('precentage', sum)
  return sum;
  
}
const handleOnBlur=()=>{
  //window.setTimeout(()=>{displaySearchCities.value=false;},200)
  
}
const photoType=ref('main');
//const svgPhoto = ref(null)
const uploadNewFile=async (type)=>{

  if( imgInfo.img==null){
    
    return ;
  }
  //console.log('svgPhoto', svgPhoto.value, type)
  render(true);
  var dataUrl=canvas.toDataURL('image/png',1);
  //console.log(dataUrl)
  
  //console.log('uploadNewFile', newFile.value );
  await profileStore.uploadNewFile({type:type,content:dataUrl});
  profileStore.fetchProfile();
  newFile.value='';

}
const handleLogoutRequested=()=>{
 router.push('/logout') 
};
const  readFile=(file)=>{
  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    const result = event.target.result;
    // Do something with result
    //console.log("result", result)
    newFile.value=result

  });

  // reader.addEventListener('progress', (event) => {
  //   if (event.loaded && event.total) {
  //     const percent = (event.loaded / event.total) * 100;
  //     //console.log(`Progress: ${Math.round(percent)}`);
  //   }
  // });
  reader.readAsDataURL(file);
}
// console.log(window.width)
var max=400;
if( window.screen.availWidth-32<400){
  max=window.screen.availWidth-32;
}
const maxWidth=ref(max);
const viewportTransform = {
      x: 0,
      y: 0,
      scale: 1
    }
    let previousX = 0, previousY = 0, isDragStart=false;

  
const updatePanning = (e) => {
  const localX = e.clientX;
  const localY = e.clientY;

  //console.log('update panning', e.clientX, e.clientY)

  viewportTransform.x += localX - previousX;
  viewportTransform.y += localY - previousY;

  previousX = localX;
  previousY = localY;
}
const updateZooming = (e) => {
  //const oldScale = viewportTransform.scale;
  const oldX = viewportTransform.x;
  const oldY = viewportTransform.y;

  const localX = max/2;//e.clientX;
  const localY = max/2;//e.clientY;

  const previousScale = viewportTransform.scale;

  const newScale = viewportTransform.scale += e.deltaY * -0.01;
  //console.log('newScale', newScale)

  const newX = localX - (localX - oldX) * (newScale / previousScale);
  const newY = localY - (localY - oldY) * (newScale / previousScale);

  viewportTransform.x = newX;
  viewportTransform.y = newY;
  viewportTransform.scale = newScale;
}

const onMouseWheel = (e) => {
  updateZooming(e)

  render()

  
}
const onMouseMove = (e) => {
  if( isDragStart){
    updatePanning(e);
    render()

  }
}
const onMouseDown=(e)=>{

  isDragStart=true;
  previousX = e.clientX;
  previousY = e.clientY;

  //console.log('onmousedown', previousX, previousY)
}

const onMouseUp=(/*e*/)=>{
  isDragStart=false;
  //console.log('onmouseup',e)
}
//https://harrisonmilbradt.com/articles/canvas-panning-and-zooming
function render(discardCicle){
  if( ctx!=null){

    if( imgInfo.w*viewportTransform.scale<max){
      viewportTransform.scale=max/imgInfo.w
    }
    if( imgInfo.h*viewportTransform.scale<max){
      viewportTransform.scale=max/imgInfo.h
    }
    if( viewportTransform.x>0){
      viewportTransform.x=0;
    }
    if( viewportTransform.y>0){
      viewportTransform.y=0;
    }

    if( viewportTransform.x + imgInfo.w*viewportTransform.scale<max){
      viewportTransform.x=max-imgInfo.w*viewportTransform.scale;
    }

    if( viewportTransform.y + imgInfo.h*viewportTransform.scale<max){
      viewportTransform.y=max-imgInfo.h*viewportTransform.scale;
    }

    //console.log('x', viewportTransform.x, 'y', viewportTransform.y, 'scale', viewportTransform.scale)

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setTransform(viewportTransform.scale, 0, 0, viewportTransform.scale, viewportTransform.x, viewportTransform.y);
    
    if( imgInfo.img!=null){
      ctx.drawImage(imgInfo.img,0,0,imgInfo.w,imgInfo.h);
    }
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    if( discardCicle!=true){
      ctx.strokeStyle = "rgb(255,255,255)";
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.arc(max/2,max/2, max/2, 0, 2 * Math.PI);
      ctx.stroke();
    }
  }
}

function handleTouch(e, singleTouchHandler)
{
    if ( e.touches.length == 1 )
    {
        singleTouchHandler(e.touches[0])
    }
    else if ( e.touches.length == 2)
    {
      if( e.type=='touchmove'){
        isDragStart = false
        handlePinch(e)
        render()
      }
      if( e.type=='touchdown'){
        initialPinchDistance=null   
      }

    }
    e.stopPropagation();
}

 let initialPinchDistance = null
 let initialPinchScale=null;
// let lastZoom = cameraZoom

function handlePinch(e)
{
    e.preventDefault()
    
    let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
    let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }
    
    // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
    let currentDistance = Math.sqrt((touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2)
    
    if (initialPinchDistance == null)
    {
        initialPinchDistance = currentDistance
        initialPinchScale=viewportTransform.scale;
    }
    else
    {
      //viewportTransform.scale=(currentDistance/initialPinchDistance)*initialPinchScale ;
      
      const oldX = viewportTransform.x;
      const oldY = viewportTransform.y;

      const localX = max/2;//e.clientX;
      const localY = max/2;//e.clientY;

      const previousScale = viewportTransform.scale;

      const newScale = (currentDistance/initialPinchDistance)*initialPinchScale ;
      //console.log('newScale', newScale)

      const newX = localX - (localX - oldX) * (newScale / previousScale);
      const newY = localY - (localY - oldY) * (newScale / previousScale);

      viewportTransform.x = newX;
      viewportTransform.y = newY;
      viewportTransform.scale = newScale;


    }
}

var canvas = null;//document.getElementById("canvas");
var ctx = null;

var imgInfo={
  img:null,
  w:0,
  h:0
}
const handleImgLoaded=(e)=>{
  console.log('handleImgLoaded', e, e.target, e.target.width)
  
  viewportTransform.x = 0;
  viewportTransform.y =0;
  viewportTransform.scale =1;

  var width= e.target.width;
  var height= e.target.height;
  
  //viewportTransform
  if( width>height){
    // height=max/width*height;
    // width=max;
    width=max/height*width;
    height=max;
    viewportTransform.x=(max-width)/2.0
  }else{
    // width=max/height*width;
    // height=max;
    height=max/width*height;
    width=max;
    viewportTransform.y=(max-height)/2.0
  }
  canvas.width=max;
  canvas.height=max;
  //console.log(e.target.width, e.target.height, 'w/h=>', width, height)

  imgInfo.w=width;
  imgInfo.h=height;
  imgInfo.img=e.target;

  render();
}

const selectFile=(e)=>{
  console.log("selectFile" ,e, e.target.files)
  if( e.target.files!= null && e.target.files.length>0){
    readFile(e.target.files[0]);
  }
}
const cities_matched = computed(() => {
  return citiesStore.matched;
});

watch(profile, (newVal)=>{
  checkProfileOnboarding()
  if((newVal.city||'')==''){
    handleCurrentLocation();
  }
})
const locationEnabled=ref(false);

const handleCurrentLocation=async ()=>{

  navigator.geolocation.getCurrentPosition(
        async (pos) =>{
          // alert('pos')
          // alert(pos)
          console.log('pos', pos)
          const crd = pos.coords;
        
          var cities= await citiesStore.nearby({long:crd.longitude, lat:crd.latitude});
          if(cities && cities.length>0){
            selectLocation(cities[0]);
          }
      }, 
      /*error*/ (err) =>{
        // alert('err'+err.message)
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }, 
      /*options*/
      {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0,
      }
      );
}
const selectLocation=(city)=>{
  profile.value.city=city.label;
  // profile.value.geo_location={
  //   lat:+city.latitude,
  //   long:+city.longitude
  // };

  profile.value.geo_location={ type: "Point", coordinates: [+city.longitude, +city.latitude ] }

  displaySearchCities.value=false;
}

const handleDeleteProfile=async ()=>{
  if (window.confirm('Supprimer définitivement mon profil et toutes les données associées ?')){
    await profileStore.deleteProfile();
    router.push('/');
  }

}
const checkProfileOnboarding=()=>{
  console.log("checkProfileOnboarding")
  var values=['gender', 'firstName', 'age', 'city', 'searchingFor', 'searchingForGender']
  var missing='';
  for(var i=0;i<values.length;i++){
    if(missing=='' && ((profile.value[values[i]] ||'')=='')){
      //profilEdition.value=true;
      missing=values[i];
      
    }
  }
  var el=null;
  if( missing!=''){
    editField.value=missing;
    el=document.getElementById("div_"+missing);
    //console.log('missing', missing, el)
  }
  
  if( missing==''){
    if(( profile.value.main_photo||'')==''){
      el=document.getElementById("a_mainPhoto");
    }
  }
  if( el){
      window.setTimeout(()=>{el.click();el.scrollIntoView({behavior: 'smooth'})},500)
      //el.click();
    }

  
}

const handleMainPhotoClick=()=>{
  console.log('handleMainPhotoClick')
  photoType.value='main';
  inputFile.value.click();
}
const enregistrerUserClick=async ()=>{
  
  await profileStore.saveProfile(profile.value)
  //checkProfileOnboarding();
  profilEdition.value=false;
  
}
onMounted(() => {
  if( !tokenStore.isLoggedIn){
    
    router.push('/')
  }else{
    
    locationEnabled.value= "geolocation" in navigator;
    profileStore.fetchProfile();

    canvas =document.getElementById("canvas");
    ctx =canvas.getContext("2d");
    canvas.addEventListener('mousedown', onMouseDown);
    canvas.addEventListener('mouseup', onMouseUp);
    canvas.addEventListener('mousemove', onMouseMove);
    canvas.addEventListener("wheel", onMouseWheel);
    // canvas.addEventListener('mousedown', onPointerDown)
     canvas.addEventListener('touchstart', (e) => handleTouch(e, onMouseDown))
    // canvas.addEventListener('mouseup', onPointerUp)
     canvas.addEventListener('touchend',  (e) => handleTouch(e, onMouseUp))
    // canvas.addEventListener('mousemove', onPointerMove)
     canvas.addEventListener('touchmove', (e) => handleTouch(e, onMouseMove))
    // canvas.addEventListener( 'wheel', (e) => adjustZoom(e.deltaY*SCROLL_SENSITIVITY))
    
    window.scrollTo(0,0);
    }
});
</script>